import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { URL_API } from '../utils/const';
import { MonitoringService } from './monitoring.service';

@Injectable()
export class ApiService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private notification: NzNotificationService,
    private monitoringService: MonitoringService,
  ) { }

  login(body){
    return this.http.post<any>(`${URL_API}/auth/login`, body,
      { 
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Accept': 'application/x.edutec.v1+json',
        })
      }
    ).pipe(map(resp => {
      if(resp.status != 'OK'){
        return resp;
      } else {
        localStorage.setItem('user', JSON.stringify(resp.data));
        return resp;
      }
    }));
  }

  logout(username?: any, isExpired?: boolean) {
    const user = JSON.parse(localStorage.getItem('user'));
    localStorage.removeItem('user');
    localStorage.removeItem('@pnld_filtro_base');
    localStorage.removeItem('@classificacoes_filter_apoio');

    this.monitoringService.forgetUser();

    this.router.navigate(['/login'], { queryParams: { isExpired }});

    return this.http.post<any>(`${URL_API}/auth/logout`, username,
      { 
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Accept': 'application/x.edutec.v1+json',
          'Authorization': user != null ? user.access_token : ''
        })
      }
    );/*.pipe(map(resp => {
      if(resp.status == 'OK'){
        localStorage.removeItem('user');
      }
    }));*/
  }

  get<T>(endpoint: string): Observable<T>{
    const user = JSON.parse(localStorage.getItem('user'));

    return this.http.get<T>(`${URL_API}/${endpoint}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/x.edutec.v1+json',
        'Authorization': user != null ? user.access_token : ''
      })
    }).pipe(
      catchError((err: HttpErrorResponse | any) => this.errorHandler(err))
    );
  }

  get_com_params<T>(endpoint: string, params: {}): Observable<T>{
    const user = JSON.parse(localStorage.getItem('user'));

    let parametros = new HttpParams();

    for(const chave in params){
      parametros = parametros.append(chave, params[chave]);
    }
    
    return this.http.get<T>(`${URL_API}/${endpoint}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/x.edutec.v1+json',
        'Authorization': user != null ? user.access_token : ''
      })
      ,params:parametros
    }).pipe(
      catchError((err: HttpErrorResponse | any) => this.errorHandler(err))
    );
  }

  post<T>(endpoint: string, body: any, headers?: { [key: string]: string }): Observable<T>{
    const user = JSON.parse(localStorage.getItem('user'));

    return this.http.post<T>(`${URL_API}/${endpoint}`, body, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/x.edutec.v1+json',
        'Authorization': user != null ? user.access_token : '',
        ...headers,
      })
    }).pipe(
      catchError((err: HttpErrorResponse | any) => this.errorHandler(err))
    );
  }

  put<T>(endpoint: string, body: any): Observable<T> {
    const user = JSON.parse(localStorage.getItem('user'));

    return this.http.put<T>(`${URL_API}/${endpoint}`, body, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/x.edutec.v1+json',
        'Authorization': user != null ? user.access_token : ''
      })
    }).pipe(
      catchError((err: HttpErrorResponse | any) => this.errorHandler(err))
    );
  }

  delete(endpoint: string){
    const user = JSON.parse(localStorage.getItem('user'));

    return this.http.delete(`${URL_API}/${endpoint}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/x.edutec.v1+json',
        'Authorization': user != null ? user.access_token : ''
      }),
    }).pipe(
      catchError((err: HttpErrorResponse | any) => this.errorHandler(err))
    );
  }
  
  upload(endpoint, formData, isProgress: boolean = true){
    const user = JSON.parse(localStorage.getItem('user'));

    const req = new HttpRequest('POST', endpoint, formData, {
      headers: new HttpHeaders({
        'Accept': 'application/x.edutec.v1+json',
        'Authorization': user != null ? user.access_token : ''
      }),
      reportProgress : isProgress,
      //withCredentials: true
    });

    return this.http.request(req).pipe(
      catchError((err: HttpErrorResponse | any) => this.errorHandler(err))
    );
  }

  request(method: string, endpoint, formData?: any){
    if(method == 'POST') {
      return this.post(endpoint, formData).pipe(
        catchError((err: HttpErrorResponse | any) => this.errorHandler(err))
      );
    } else {
      return this.get(endpoint).pipe(
        catchError((err: HttpErrorResponse | any) => this.errorHandler(err))
      );
    }
  }
  
  relatorio(endpoint: string, body: { [key: string]: any }) {
    const user = JSON.parse(localStorage.getItem('user'));

    return this.http.post(`${URL_API}/${endpoint}`, body, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/x.edutec.v1+json',
        'Authorization': user != null ? user.access_token : ''
      }),
      responseType: 'blob' as 'json',
      reportProgress : true,
      observe:"events",
    }).pipe(
      catchError((err: HttpErrorResponse | any) => this.errorHandler(err))
    );
  }

  relatorioGet(endpoint: string) {
    const user = JSON.parse(localStorage.getItem('user'));

    return this.http.get(`${URL_API}/${endpoint}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/x.edutec.v1+json',
        'Authorization': user != null ? user.access_token : ''
      }),
      responseType: 'blob' as 'json',
      reportProgress : true,
      observe:"events",
    }).pipe(
      catchError((err: HttpErrorResponse | any) => this.errorHandler(err))
    );
  }

  handleLogin(code = null) {
    this.router.navigate(['/login']);
  }

  removeTokenExpired(isNotification = false) {
    localStorage.removeItem('user');
    localStorage.removeItem('@pnld_filtro_base');
    localStorage.removeItem('@classificacoes_filter_apoio');
    this.router.navigate(['/login']);

    if (isNotification) {
      this.notification.create(
        'warning',
        'Token expirado',
        'Não foi possivel acessar porque seu token de acesso expirou.'
      );
    }
  }

  errorHandler(err: HttpErrorResponse | any) {
    if (err instanceof HttpErrorResponse) {

        switch (err.status) {
            case 401:
                console.log('errorHandler:: err.status == 401');
                this.removeTokenExpired();
                this.handleLogin({code: 401});
                break;
                break;
        }
    }

    return throwError(err);
  }
}
