import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild, isDevMode } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../../models/user.model';

import { ApiService } from '../../services/api.service';
import { AuthGovbrService } from 'src/app/pages/user/shared/services/auth-govbr.service';
import { UsuarioService } from '../../services/usuario.service';
import { NzNotificationRef, NzNotificationService } from 'ng-zorro-antd/notification';
import { NzModalService } from 'ng-zorro-antd/modal';

export interface NotificationItem {
    id: number;
    titulo: string,
    mensagem: string;
    read: boolean;
    read_at?: boolean;
}

@Component({
  selector: 'app-layout-header',
  templateUrl: './layout-header.component.html',
  styleUrls: ['./layout-header.component.less']
})
export class LayoutHeaderComponent implements OnInit {

  @ViewChild('notificationTemplate', { static: false }) notificationTemplate: TemplateRef<any>;
  @Input() isButtonVisible = true;
  @Input() isButtonCollapsed = false;
  @Output() onCollapsed = new EventEmitter();
  

  user: User;
  scrolled: boolean = false;
  
  notificacoesAll = [];
  notificacoesNaoLidas = [];
  notificacoesSalvas = [];
  notificacoesLidas = [];
  loadingNotificacoes: boolean = true;
  loadingAcao: boolean = false;

  isVisibileModalNotificacoes: boolean = false;
  notificacaoSelected = null;
  notificacaoSelectedList = null;
  notificacaoSelectedListTipo : 'lidas'|'nao-lidas'|'salvas' = null;
  notificacaoSelectedIndex = null;

  public debug: boolean = false;
  public canAcoesNotificacao: boolean = false;

  constructor(
    private api: ApiService,
    private router: Router,
    private usuarioService: UsuarioService,
    private notificationService: NzNotificationService,
    private _authGovBRService: AuthGovbrService,
    protected modal: NzModalService,
  ) { }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.debug = isDevMode();

    this.canAcoesNotificacao = this.debug && this.user?.perfil?.ds_chave == 'administrador' || this.user?.perfil?.ds_chave == 'apoio';

    this.loadNotificacoes();

    window.addEventListener('scroll', () => {
        this.scrolled = window.scrollY !== 0;
    });
   
  }

  abrirNotificacoes(): void {
    this.notificacoesNaoLidas.forEach(notificacao => {
      if (!notificacao.read) {
        this.notificationService.template(this.notificationTemplate, { nzData: notificacao, nzDuration: 0 });
      }
    });
  }

  loadNotificacoes(): void {
    this.loadingNotificacoes = true;
   
    if(this.user && this.user?.co_usuario) {
        this.usuarioService.getNotificacoes( this.user?.co_usuario ).then(result => {
          if(result && Array.isArray(result) && result.length > 0) {
            this.notificacoesAll = result.map(item => 
              {
                  let notificacao = JSON.parse(item.data);
  
                  return ({   
                      id: item.id,
                      co_notificacao: item?.co_notificacao,
                      read_at: item.read_at,
                      read: item.read_at ? true : false,
                      titulo: notificacao.titulo ? notificacao.titulo : '',
                      mensagem: notificacao.mensagem ? notificacao.mensagem : '',
                      titulo_detalhes: notificacao.titulo_detalhes ? notificacao.titulo_detalhes : null,
                      detalhes: notificacao.detalhes ? notificacao.detalhes : null,
                      created_at: item.created_at,
                      notifiable_id: item.notifiable_id,
                      saved: item.saved
                  });
              }
            );
  
            this.notificacoesNaoLidas = this.notificacoesAll.filter(notificacao => notificacao.read_at == null);
            this.notificacoesLidas = this.notificacoesAll.filter(notificacao => notificacao.read_at != null);
            this.notificacoesSalvas = this.notificacoesAll.filter(notificacao => notificacao.saved == true);
  
          } else if (result && result.status === 'ERROR' && result.error) {
            console.error('Erro ao obter notificações:', result.error);
          }
          this.loadingNotificacoes = false;
        }).catch(error => {
          this.loadingNotificacoes = false;
        });
    }
    
  }

  notificacoesNaoLidasCount(): number {
    return this.notificacoesNaoLidas.filter(n => n.read == false).length;
  }

  marcarComoLida(notificacao): void {
    notificacao.read = true;
    notificacao.read_at = new Date();
    this.loadingAcao = true;
    this.usuarioService.putNotificacaoComoLida(notificacao.co_notificacao).then( result => {
        if(result.error)
            this.notificationService.error(notificacao.titulo, result.error?.message);
        else {
            this.notificationService.success(notificacao.titulo, 'Marcada como lida');
            this.notificacaoSelectedList = this.notificacaoSelectedList?.filter(n => n.co_notificacao != notificacao.co_notificacao);
        }
        this.loadingAcao = false;
        this.loadNotificacoes();
    }).catch(error => {
        this.loadingAcao = false;
        this.notificationService.error('Erro ao marcar com lida!', error?.error?.message);
    });
  }

  marcarComoSalva(notificacao): void {
    notificacao.save = true;
    this.loadingAcao = true;

    this.usuarioService.putNotificacaoSalvar(notificacao.co_notificacao).then( result => {
        if(result.error)
            this.notificationService.error(notificacao.titulo, result.error?.message);
        else {
            this.notificationService.success(notificacao.titulo, 'Marcada como salva');
            this.notificacaoSelectedList = this.notificacaoSelectedList?.filter(n => n.co_notificacao != notificacao.co_notificacao);
        }
        this.loadingAcao = false;
        this.loadNotificacoes();
    }).catch(error => {
        this.loadingAcao = false;
        this.notificationService.error('Erro ao marcar como salva!', error?.error?.message);
    });
  }

  marcarComoExcluida(notificacao, inModal = false): void {
    this.loadingAcao = true;
    this.modal.confirm({
        nzTitle: '<b>Confirmação?</b>',
        nzContent: '<i>Tem certeza que deseja excluir a notificação? Você poderia marcar apenas como lida.</i>',
        nzOnOk: () => {

            this.notificacoesAll = this.notificacoesAll.filter(n => n.co_notificacao != notificacao.co_notificacao);

            this.usuarioService.deleteNotificacao(notificacao.co_notificacao).then( result => {
                if(result.error)
                    this.notificationService.error(notificacao.titulo, result.error?.message);
                else {
                    this.notificationService.success(notificacao.titulo, 'Excluída');
                    this.notificacaoSelectedList = this.notificacaoSelectedList?.filter(n => n.co_notificacao != notificacao.co_notificacao);
                }
                this.loadNotificacoes();
                this.loadingAcao = false;
                    
                console.log('inModal::'+inModal);
                if(inModal){
                    console.log('this.notificacoesAll.length::'+this.notificacoesAll.length);
                    if(this.notificacoesAll.length > 0)
                        this.proximaNotificacao()
                    else
                        this.closeModalNotificacoes();
                }
                   
            }).catch(error => {
                if(error.status == 404)
                    this.notificationService.warning('Erro ao excluir!', 'Sem registro a excluir. Talvez tenha sido excluído antes.');
                else
                    this.notificationService.error('Erro ao excluir!', error?.error.message);

                this.loadingAcao = false;
            })
        },
      })
    
  }

  showModalNotificacoes(notificacoes: any[], index: number, tipo: 'lidas' | 'nao-lidas' | 'salvas') {

    this.isVisibileModalNotificacoes = true;

    this.notificacaoSelectedList = notificacoes;
    this.notificacaoSelectedIndex = index;
    this.notificacaoSelected = notificacoes[index];
    this.notificacaoSelectedListTipo = tipo;

  }

  closeModalNotificacoes() {
    this.isVisibileModalNotificacoes = false;
    this.notificacaoSelectedList = null;
    this.notificacaoSelectedIndex = null;
    this.notificacaoSelected = null;
    this.notificacaoSelectedListTipo = null;
  }

  anteriorNotificacao(goToPrimeira = false) {
    if(goToPrimeira){
      this.notificacaoSelectedIndex = 0;
      this.notificacaoSelected = this.notificacaoSelectedList[this.notificacaoSelectedIndex];
      return;
    }

    if(this.notificacaoSelectedList && this.notificacaoSelectedIndex > 0){
        this.notificacaoSelectedIndex--;
        this.notificacaoSelected = this.notificacaoSelectedList[this.notificacaoSelectedIndex];
    }
  }

  proximaNotificacao(goToUltima = false) {
    if(goToUltima){
      this.notificacaoSelectedIndex = this.notificacaoSelectedList.length - 1;
      this.notificacaoSelected = this.notificacaoSelectedList[this.notificacaoSelectedIndex];
      return;
    }
    if(this.notificacaoSelectedList && this.notificacaoSelectedIndex < this.notificacaoSelectedList.length - 1){
        this.notificacaoSelectedIndex++;
        this.notificacaoSelected = this.notificacaoSelectedList[this.notificacaoSelectedIndex];
    }
  }

  logout(): void {
    this.api.logout(this.user).subscribe(
      resp => {
        this.user = undefined;
        let logoutURLGovBR = this.getGovBRURLLogoutFromLocalStorage();
        if (logoutURLGovBR) {
          window.location.href = logoutURLGovBR;
        }
      }, err => {
        this.user = undefined;
      }
    );
  }

  getGovBRURLLogoutFromLocalStorage(): string {
    return JSON.parse(localStorage.getItem('logout_url'));
  }

  scrollToTop(): void {
    window.scrollTo(0, 0);
  }

}
